import tw, { styled } from "twin.macro"

import { UnderlinedButton } from "../../Styled/Button"

export const Wrapper = tw.div`
  hidden md:flex mt-4-8 mb-6-4 justify-between
`

export const ImageWrapper = tw.div`
  w-3/5
`

export const Content = tw.div`
  w-2/5 md:pl-1-6 lg:pl-3-2 xl:pl-7-5 lg:pr-3-2 flex flex-col justify-between
`

export const Badge = tw.p`
  border-beige rounded-5 py-0-8 px-1-6 bg-beige inline-block text-14 leading-1.2 uppercase font-medium
`

export const ContentWrapper = tw.div`
  mb-1-6
`

export const Title = tw.h2`
  text-25 lg:text-35 leading-1.28 tracking-3 font-bold md:mb-1-6 xl:mb-3-2 uppercase xl:max-w-4/5
`

export const Description = tw.p`
  text-18 leading-1.56 xl:max-w-4/5
`

export const ReadArticle = styled(UnderlinedButton)`
  ${tw`mb-0-8`}
`
