import React from "react"

import { withFilters } from "./withFilters"
import { Desktop, Mobile, Filter } from "./FiltersStyles"
import { StyledDropdownSelect, StyledSelectOption, StyledInputIconWrapper } from "../../Styled/Form"
import { Icon } from "../../Icon/Icon"

export const Filters = withFilters(({ filters, additionalFilterText, activeFilter, handleFilterChange }) => (
  <>
    <Desktop>
      {filters.map(filter => (
        <Filter
          key={filter}
          size={"secondary"}
          colour={activeFilter === filter ? "black-no-hover" : "black-reverse"}
          onClick={() => handleFilterChange(filter)}
        >
          {filter}
        </Filter>
      ))}
    </Desktop>
    <Mobile>
      <StyledDropdownSelect value={activeFilter} onChange={e => handleFilterChange(e.target.value)} layout={"sortBy"}>
        <StyledSelectOption value={""} selected>
          {additionalFilterText}
        </StyledSelectOption>
        {filters.map(filter => (
          <StyledSelectOption key={filter} value={filter}>
            {filter}
          </StyledSelectOption>
        ))}
      </StyledDropdownSelect>
      <StyledInputIconWrapper>
        <Icon name={"chevron"} className={`mb-2`} width={"12"} height={"12"} />
      </StyledInputIconWrapper>
    </Mobile>
  </>
))
