import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withSort = Component => ({ name = "Sort", activeSortBy, handleSortByChange }) => {
  const { articles } = useStaticQuery(graphql`
    query SANITY_PAGE_ARTICLES_SORT {
      articles: sanityPageArticles {
        additionalSortByText
        additionalSortByPublishDateDescText
        additionalSortByPublishDateAscText
      }
    }
  `)

  const { additionalSortByText, additionalSortByPublishDateDescText, additionalSortByPublishDateAscText } = articles || {}

  Component.displayName = name
  return (
    <Component
      additionalSortByText={additionalSortByText}
      activeSortBy={activeSortBy}
      handleSortByChange={handleSortByChange}
      additionalSortByPublishDateDescText={additionalSortByPublishDateDescText}
      additionalSortByPublishDateAscText={additionalSortByPublishDateAscText}
    />
  )
}
