import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useRoutes } from "../../../hooks/useRoutes"
import { useImage } from "../../../hooks/useImage"

export const withFeaturedArticle = Component => ({ name = "FeaturedArticle", article }) => {
  const { linkResolver } = useRoutes()
  const { getFluidImage } = useImage()

  const { articles } = useStaticQuery(graphql`
    query SANITY_PAGE_ARTICLES_FEATURED_ARTICLE {
      articles: sanityPageArticles {
        additionalFeaturedArticleBadgeText
        additionalReadArticleText
      }
    }
  `)

  const { additionalFeaturedArticleBadgeText, additionalReadArticleText } = articles || {}

  const { title, description, image: originalImage } = article || {}
  const link = linkResolver(article)
  const image = getFluidImage(originalImage, { maxWidth: 800 })

  Component.displayName = name
  return (
    <Component
      title={title}
      description={description}
      link={link}
      image={image}
      additionalFeaturedArticleBadgeText={additionalFeaturedArticleBadgeText}
      additionalReadArticleText={additionalReadArticleText}
    />
  )
}
