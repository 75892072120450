import React from "react"
import { Link } from "gatsby"

import { withFeaturedArticle } from "./withFeaturedArticle"
import { Wrapper, ImageWrapper, Content, Badge, ContentWrapper, Title, Description, ReadArticle } from "./FeaturedArticleStyles"
import { Image } from "../../Image/Image"

export const FeaturedArticle = withFeaturedArticle(({ title, description, link, image, additionalFeaturedArticleBadgeText, additionalReadArticleText }) => (
  <Wrapper>
    <ImageWrapper>
      <Image image={image} ratio={"8/5"} />
    </ImageWrapper>
    <Content>
      <ContentWrapper>
        <Badge>
          {additionalFeaturedArticleBadgeText}
        </Badge>
      </ContentWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContentWrapper>
      <ReadArticle as={link?.url ? Link : null} to={link?.url}>
        <span>
          {additionalReadArticleText}
        </span>
      </ReadArticle>
    </Content>
  </Wrapper>
))