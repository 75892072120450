import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withFilters = Component => ({ name = "Filters", filters, activeFilter, handleFilterChange }) => {
  const { articles } = useStaticQuery(graphql`
    query SANITY_PAGE_ARTICLES_FILTERS {
      articles: sanityPageArticles {
        additionalFilterText
      }
    }
  `)

  const { additionalFilterText } = articles || {}

  Component.displayName = name
  return (
    <Component filters={filters} additionalFilterText={additionalFilterText} activeFilter={activeFilter} handleFilterChange={handleFilterChange} />
  )
}
