import tw, { styled } from "twin.macro"

const paddingTop = "pt-3 md:pt-4-1"
export const Wrapper = tw.div`
  max-w-xl mx-auto md:px-3 px-2-4 pb-4 md:pb-8
`

export const Title = styled.div`
  ${({ hideTitle }) => hideTitle && tw`hidden`}
  ${tw`
  font-bold text-35 md:text-60 leading-1.28 md:leading-none tracking-3 md:tracking-2 pb-5 ${paddingTop} md:pb-8 text-center uppercase
`}
`

export const Control = styled.div`
  ${({ hideTitle }) => hideTitle && tw`${paddingTop}`}
  ${tw`flex mb-2 md:mb-4-8 flex-col md:flex-row`}
`

export const Divider = tw.hr`hidden md:block`

export const List = tw.div`grid grid-cols-1 md:grid-cols-3 gap-x-3 gap-y-4-6 md:gap-y-6-4 mb-6-4`

export const Empty = tw.p`text-center py-2-4`
