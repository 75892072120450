import tw, { styled } from "twin.macro"

import { StyledButton } from "../../Styled/Button"
import { StyledSelectWrapper } from "../../Styled/Form"

export const Desktop = tw.div`
  hidden md:flex flex-auto justify-start
`

export const Mobile = styled(StyledSelectWrapper)`
  ${tw`md:hidden mb-1-2 uppercase`}
`

export const Filter = styled(StyledButton)`
  ${tw`mr-1`}
`
