import React from "react"

import { withArticles } from "./withArticles"
import { Title, Control, Divider, List, Wrapper, Empty } from "./ArticlesStyle"
import { Filters } from "./Filters/Filters"
import { Sort } from "./Sort/Sort"
import { FeaturedArticle } from "./FeaturedArticle/FeaturedArticle"
import { Card } from "./Card/Card"
import { Pagination } from "../Pagination/Pagination"

export const Articles = withArticles(
  ({
    title,
    hideTitle,
    articles,
    filters,
    featuredArticle,
    activeFilter,
    handleFilterChange,
    activeSortBy,
    handleSortByChange,
    currentPage,
    handlePageChange,
    totalPageCount,
  }) => (
    <Wrapper>
      <Title hideTitle={hideTitle}>{title}</Title>

      <Control hideTitle={hideTitle}>
        {filters?.length > 0 && <Filters filters={filters} activeFilter={activeFilter} handleFilterChange={handleFilterChange} />}
        <Sort activeSortBy={activeSortBy} handleSortByChange={handleSortByChange} />
      </Control>

      <Divider />

      {featuredArticle ? <FeaturedArticle article={featuredArticle} /> : null}

      {articles?.length > 0 ? (
        <>
          <List>
            {articles.map(article => (
              <Card key={article._id} item={article} />
            ))}
          </List>
          <Pagination totalPageCount={totalPageCount} currentPage={currentPage} handlePageChange={handlePageChange} />{" "}
        </>
      ) : (
        <Empty>No articles</Empty>
      )}
    </Wrapper>
  )
)
