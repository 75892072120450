import React from "react"

import { useArticles } from "../../hooks/useArticles"

export const withArticles = Component => ({ name = "Articles", page, articles }) => {
  const { title, hideTitle, featuredArticle, size } = page
  const { totalCount } = articles

  const { items, availableFilters, activeFilter, activeSortBy, currentPage, handleFilterChange, handleSortByChange, handlePageChange } = useArticles(
    size
  )

  const totalPageCount = Math.ceil(totalCount / size)

  Component.displayName = name
  return (
    <Component
      title={title}
      hideTitle={hideTitle}
      articles={items}
      filters={availableFilters}
      featuredArticle={featuredArticle}
      activeFilter={activeFilter}
      handleFilterChange={handleFilterChange}
      activeSortBy={activeSortBy}
      handleSortByChange={handleSortByChange}
      currentPage={currentPage}
      handlePageChange={handlePageChange}
      totalPageCount={totalPageCount}
    />
  )
}
