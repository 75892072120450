import React from "react"
import { graphql } from "gatsby"

import { Articles as Page } from "../components/Articles/Articles"

export const query = graphql`
  query {
    page: sanityPageArticles {
      title
      size
      hideTitle
      featuredArticle: _rawFeaturedArticle(resolveReferences: { maxDepth: 3 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
    articles: allSanityArticle(filter: { attributes: { publishedAt: { ne: null } } }) {
      totalCount
    }
  }
`

const Component = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
