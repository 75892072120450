import React from "react"

import { withSort } from "./withSort"
import { Wrapper } from "./SortStyles"
import { StyledSelectWrapper, StyledDropdownSelect, StyledSelectOption, StyledInputIconWrapper } from "../../Styled/Form"
import { Icon } from "../../Icon/Icon"

export const Sort = withSort(
  ({ additionalSortByText, activeSortBy, handleSortByChange, additionalSortByPublishDateDescText, additionalSortByPublishDateAscText }) => (
    <Wrapper>
      <StyledSelectWrapper>
        <StyledDropdownSelect value={activeSortBy} onChange={e => handleSortByChange(e.target.value)} layout={"sortBy"}>
          <StyledSelectOption disabled value={""} selected>
            {additionalSortByText}
          </StyledSelectOption>
          <StyledSelectOption value={"DESC"}>{additionalSortByPublishDateDescText}</StyledSelectOption>
          <StyledSelectOption value={"ASC"}>{additionalSortByPublishDateAscText}</StyledSelectOption>
        </StyledDropdownSelect>
        <StyledInputIconWrapper>
          <Icon name={"chevron"} className={`mb-2`} width={"12"} height={"12"} />
        </StyledInputIconWrapper>
      </StyledSelectWrapper>
    </Wrapper>
  )
)
